import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { intializeSelect } from '../../../scripts/materializescript';
import { fetchCategoryList, setCategory, setLanguage } from '../../../redux/stuff/actioncreators';

function SelectContainer() {
    const dispatch = useDispatch();
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [error, setError] = useState('');
    const [languageOptions, setLanguageOptions] = useState([])
    const stuff = useSelector(({ stuffState }) => stuffState);
    const userState = useSelector(({ userState }) => userState);

    useEffect(() => {
        intializeSelect();
    }, [categoryOptions, languageOptions])
    useEffect(() => {
        if(!stuff.error && !stuff.isAvailable){
            dispatch(fetchCategoryList());
        }
        else if(stuff.isAvailable){
            setOptions() 
        }
        userState.savedError ? setError('Please try again') : '';
    }, [stuff, userState])

    const setOptions = () => {
        let cOptions = [], lOptions = [];
        for (let i = 0; i < stuff.categories.length; i++) {
            let value = stuff.categories[i].category;
            let label = value[0].toUpperCase() + value.substring(1);
            cOptions.push({ value, label });
        }
        for (let i = 0; i < stuff.languages.length; i++) {
            let value = stuff.languages[i];
            let label = value[0].toUpperCase() + value.substring(1);
            lOptions.push({ value, label });
        }
        setLanguageOptions(lOptions);
        setCategoryOptions(cOptions);
    }
    const handleCategory = (e) => {
        let value = e.target.value.toLowerCase();
        dispatch(setCategory(value))
    }
    const handleLanguage = (e) => {
        let value = e.target.value.toLowerCase();
        dispatch(setLanguage(value))
    }
    return (
        <>
            <li>
                <SelectInput
                    label="Category"
                    options={categoryOptions}
                    disabledOption="Choose"
                    handleChange={handleCategory}
                    value={stuff.selectedCategory}
                />
            </li>
            <li>
                <SelectInput
                    label="Language"
                    options={languageOptions}
                    disabledOption="Choose"
                    handleChange={handleLanguage}
                    value={stuff.selectedLanguage}
                />
            </li>
        </>
    )
}

export default SelectContainer
function SelectInput({ label, options, value, disabledOption, handleChange, multiple }) {
    // /////// default multi select
    return (
        <div className="input-field col s10 offset-s1 mb-0 custom-select">
            <select className={label} multiple={multiple} value={value} onChange={handleChange}>
                <option value="0" disabled>{disabledOption}</option>
                {
                    options.map((ele, idx) => {
                        return <option key={idx} value={ele.value}>{ele.label}</option>
                    })
                }

            </select>
            <label className="list-label">{label}</label>
        </div>
    )
}