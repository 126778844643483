import React from 'react';
import { Link } from 'react-router-dom'
import './404page.css'

function Page404() {
    return (
        <div className="full-page main page-404">
            <div className="center-container">
                <img src='./fourOhFour.gif' alt="404-gif" />
                <p className="white-text-shadow">
                    Sorry page you requested is not found, return to{" "}
                    <Link to="/">Goodtube</Link>
                </p>

            </div >
        </div >
    )
}

export default Page404
