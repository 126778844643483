import React, { Component } from "react";
import { Route, Switch, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import loadable from '@loadable/component'
import { Helmet } from "react-helmet";

import "./homecontainer.css";
import RequireAuth from "../AuthPages/protectedroute";
import CategoryContainer from "./CategoryList/CategoryContainer";
import LanguageNavContainer from "./LanguageNav/LanguageNavContainer";
import NavbarContainer from "../Navbar/NavbarContainer.js";
// import PageLoadingContainer from "../Common/PageLoading/PageLoadingContainer";
// import PlayingCardContainer from "./SkeletonLoader/PlayingCard/PlayingCardContainer";
const ModalContainer = loadable(() => import('../Common/ModalContainer/ModalContainer'))
const PlayingContainer = loadable(() => import('./PlayingPage/PlayingContainer.js'))
const VideoListContainer = loadable(() => import('./VideoList/VideoListContainer'))

// import { addVideo } from "../../redux/videos/actioncreators.js";
// import { saveVideo } from "../../redux/user/actionCreators/Videos";
// import { addDummyVideos } from '../../scripts/videomock';


class HomeContainer extends Component {
  componentDidMount() {

    let user = {
      username: "mohinder",
      password: "password",
    };

    // console.log(this.props,'kkdkjk k')
  }
  isFavPage = () => {
    let { pathname } = this.props.location;
    return pathname === "/favorites"
  }
  returnClasses = () => {
    if (this.isFavPage()) {
      return 'videos-container show-label'
    }
    else {
      return 'videos-container';
    }
  }

  render() {
    return (
      <div className="home-container" onClick={this.handleClick}>
        <Helmet>
          <title>Goodtube | {this.isFavPage() ? 'Favorite' : 'Home'}</title>
          <meta name="keywords" content={'goodtube,videos, good videos,great videos'}></meta>
          <meta name="description" content='Watch good videos from youtube and share with others.'></meta>
        </Helmet>
        <NavbarContainer />
        <aside className="left-container">
          <CategoryContainer />
        </aside>
        <ModalContainer />
        <main className={this.returnClasses()}>
          <LanguageNavContainer />
          <Switch>
            <Route path="/" exact component={VideoListContainer} />

            <Route
              path="/playing/:slug"
              render={({ match }) => <PlayingContainer match={match} />}
            />
            <Route path="/favorites" component={RequireAuth(VideoListContainer)} />
            <Route path="/*">
              <Redirect to="/404Error" />
            </Route>
          </Switch>
        </main>
      </div>
    );
  }
}
// desturcturing state.user|stuff|videos
function mapStateToProps({ userState, modalState }) {
  return {
    user: userState.user,
  };
}
export default connect(mapStateToProps, null)(HomeContainer);
