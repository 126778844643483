import React, { Component } from "react";
import { connect } from "react-redux";
import CategoryList from "./categorylist";

import {
  fetchCategoryList,
  setCategory,
} from "../../../redux/stuff/actioncreators.js";
import "./leftcontainer.css";

class CategoryContainer extends Component {
  componentDidMount() {
    // let data = {
    //   category: "technology",
    //   icon: "memory",
    //   priority: "6",
    // };
    if (!this.props.isAvailable) {
      this.props.dispatch(fetchCategoryList());
    }

    // fetch("/stuff/category/update", {
    //   method: "POST",
    //   mode: "cors",
    //   body: JSON.stringify(data),
    //   headers: {
    //     "Content-Type": "application/json",
    //     // 'Content-Type': 'application/x-www-form-urlencoded',
    //   },
    // })
    //   .then((res) => {
    //     console.log(res.json());
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }
  categoryHandler = (category) => {
    this.props.dispatch(setCategory(category));
  };
  render() {
    return (
      <>
        <h3>Categories</h3>
        <CategoryList
          list={this.props.categoryList}
          current={this.props.selectedCategory}
          clickHandler={(category) => this.categoryHandler(category)}
        />
      </>
    );
  }
}

function mapStateToProps({ stuffState }) {
  return {
    categoryList: stuffState.categories,
    isAvailable: stuffState.isAvailable,
    selectedCategory: stuffState.selectedCategory,
  };
}

export default connect(mapStateToProps, null)(CategoryContainer);
