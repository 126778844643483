import axiosInstance from '../axiosInstance';
import { FETCH_QUOTES_ERROR, FETCH_QUOTES_SUCCESS } from './constactions';

const fetchQuotes = () => {
    return function (dispatch) {
        axiosInstance.get("/quotes")
            .then(r => {
                console.log('quote res:: ', r)
                dispatch({ type: FETCH_QUOTES_SUCCESS, quotes: r.data })
            })
            .catch(err => {
                dispatch({ type: FETCH_QUOTES_ERROR })
            })
    }
}

export { fetchQuotes };