import { combineReducers } from "redux";
import videoReducer from "./videos/reducer.js";
import userReducer from "./user/reducer.js";
import routeReducer from "./routes/reducer.js";
import stuffReducer from "./stuff/reducer.js";
import playerReducer from "./playing/reducer";
import feedbackReducer from "./feedback/reducer";
import modalReducer from "./modalContainer/reducer";
import { quoteReducer } from "./quotes/reducer.js";

export default combineReducers({
  userState: userReducer,
  videoState: videoReducer,
  stuffState: stuffReducer,
  playingState: playerReducer,
  feedbackState: feedbackReducer,
  modalState: modalReducer,
  quoteState: quoteReducer,
});

// state obj in redux example
// userState:{..}, videoState:{...}