

export const userState = {
  user: null,
  isAuthenticated: false,
  isLoginError: null,
  favorites: [],
  history: [],
  savedVideos: [],
  savedError: null,
  isFav: false,
  favError: null,
  favCount: 0,
  signUp: "",
};
