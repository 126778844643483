import React from "react";
import { Link } from "react-router-dom";
import LetterAvatar from "./LetterAvatar";

export default function UserMenu({ user, handleLogout }) {
  return (
    <div className="user-menu">
      <ul>
        <li className="valign-wrapper user">
          <h2>{user.username}</h2>
          <LetterAvatar user={user} />
        </li>
        <li>
          <Link to="/account">Account</Link>
        </li>
        {/* <li>
          <Link to="/history">History</Link>
        </li> */}
        <li>
          <Link to="/favorites">Favorites</Link>
        </li>
        <li>
          <Link to="/share">Shared </Link>
        </li>
        <li>
          <a>
            <span onClick={handleLogout}>Logout</span>
          </a>
        </li>
      </ul>
    </div>
  );
}
