import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class IconLink extends Component {
  goToFavorites = () => {
    this.props.history.push("/favorites");
  };
  isFavorite = () => {
    if (this.props.location.pathname === "/favorites") {
      return true;
    }
    return false;
  };
  render() {
    return (
      <div className="icons valign-wrapper">
        {/* <span className="material-icons">view_list</span>
        <span className="material-icons">history</span> */}
        <span
          onClick={this.goToFavorites}
          className={
            this.isFavorite() ? `active material-icons` : "material-icons"
          }
        >
          star_rate
        </span>
      </div>
    );
  }
}
export default withRouter(IconLink);
