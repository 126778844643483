import stuff from "./defaultstate.js";
import {
  FETCH_STUFF_SUCCESS,
  FETCH_STUFF_ERROR,
  SET_LANGUAGE,
  SET_CATEGORY,
} from "./constactions";

const stuffReducer = (state = stuff, action) => {
  switch (action.type) {
    case FETCH_STUFF_SUCCESS:
      return {
        ...state,
        categories: action.categories,
        languages: action.languages,
        isAvailable: true,
      };
    case FETCH_STUFF_ERROR:
      return { ...state, isAvailable: false, error: action.error };
    case SET_LANGUAGE:
      return { ...state, selectedLanguage: action.language };
    case SET_CATEGORY:
      return { ...state, selectedCategory: action.category };

    default:
      return state;
  }
};

export default stuffReducer;
// case LANGUAGE_ADDED:
//   return {
//     ...state,
//     isAvailable: true,
//     languages: [...state.languages, action.newlanguage],
//   };
// case CATEGORY_ADDED:
//   return {
//     ...state,
//     isAvailable: true,
//     categories: [...state.categories, action.newcategory],
//     icons: [...state.icons, action.newicon],
//   };
