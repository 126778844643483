import React, { Component } from "react";
import { withRouter } from 'react-router-dom'
import "./navbar.css";

import LinkList from "./links.js";
import Logo from "./logo";
import MenuBtn from "./HomeMenu/MenuBtn.js";
import UserBtn from "./UserMenu/UserBtn";

class NavbarContainer extends Component {
  constructor(props) {
    super(props)
  }
  goToHome = () => {
    this.props.history.push("/")
  }
  render() {
    return (
      <header>
        <nav className="valign-wrapper">
          <MenuBtn />
          <div onClick={this.goToHome}>
            <Logo />
            <h1>GOODTUBE</h1>
          </div>
          <LinkList />
          <UserBtn />
        </nav>
      </header>
    );
  }
}

export default withRouter(NavbarContainer);
