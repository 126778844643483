import React from "react";

function makeList({ list, clickHandler, current }) {
  let liList = [];
  list.map((ele) => {
    liList[ele.priority - 1] = (
      <li
        key={ele.priority}
        onClick={() => clickHandler(ele.category)}
        className={ele.category === current ? "active" : ""}
      >
        <span className="material-icons">{ele.icon}</span>
        {ele.category[0].toUpperCase() + ele.category.substring(1)}
      </li>
    );
  });
  return liList;
}
export default function CategoryList(obj) {
  return (
    <ul className="category-list">{makeList(obj)}</ul>

    // <ul>
    //   <li>
    //     <span className="material-icons">done_all</span>All
    //   </li>
    //   <li>
    //     <span className="material-icons">language</span>Knowledge
    //   </li>
    //   <li>
    //     <span className="material-icons">library_music</span>Songs
    //   </li>
    //   <li>
    //     <span className="material-icons">accessibility_new</span>Motivational
    //   </li>
    //   <li>
    //     <span className="material-icons">toys</span>Science
    //   </li>
    //   <li>
    //     <span className="material-icons">memory</span>Technology
    //   </li>
    // </ul>
  );
}
