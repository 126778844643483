import React from "react";

export default function Logo() {
  return (
    <div className="logo hide-on-mobile">
      <img
        className="responsive-img"
        src="/gtube_icons/android-chrome-192x192.png"
      />
    </div>
  );
}
