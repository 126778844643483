import React from "react";
export default function LanguageList({ list, current, clickHandler }) {
  return (
    <ul>
      {list.map((ele, idx) => {
        return (
          <li
            onClick={clickHandler}
            key={idx}
            className={ele === current ? "active" : ""}
          >
            {ele[0].toUpperCase() + ele.substring(1, ele.length)}
          </li>
        );
      })}
    </ul>
  );
}
