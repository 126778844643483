import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { fetchQuotes } from '../../../redux/quotes/actioncreator';


function PageLoadingContainer() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchQuotes());
    }, [])
    const { quotes, isError, isAvailable } = useSelector(state => state.quoteState);
    let index = undefined;
    index = Math.floor(Math.random() * quotes.length);

    if (isError || isAvailable) {
        return (
            <div className="full-page main">
                <div className="center-container">
                    <div className="loading-rocket">
                        <img src='./loadingRocket.gif' alt="loading-gif" />
                    </div>
                    <figure className="white-text-shadow">
                        <blockquote>
                            <p>
                                {quotes[index].quote}
                            </p>
                        </blockquote>
                        <figcaption>— {quotes[index].author}</figcaption>
                    </figure>
                </div >
            </div >
        )
    }
    return null;
}

export default PageLoadingContainer
