import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import loadable from '@loadable/component'

import "./app.css";

import HomeContainer from "./components/Homepage/HomeContainer.js";
import LoginHandler from "./components/AuthPages/LoginHandler.js";
// import HistoryContainer from "./components/Historypage/HistoryContainer.js";
import RequireAuth from "./components/AuthPages/protectedroute.js";
import PageLoading from './components/Common/PageLoading/PageLoadingContainer'

const AboutContainer = loadable(() => import('./components/AboutPage/About'), { fallback: <PageLoading /> })
const LoginContainer = loadable(() => import('./components/AuthPages/LoginContainer.js'), { fallback: <PageLoading /> })
const FeedbackContainer = loadable(() => import('./components/FeedbackPage/FeedbackContainer'), { fallback: <PageLoading /> })
const AccountPage = loadable(() => import('./components/AccountPage/AccountPageContainer.js'), { fallback: <PageLoading /> })
const SharePage = loadable(() => import('./components/SharePage/ShareContainer'), { fallback: <PageLoading /> })
import Page404 from "./components/Common/404Page/Page404";
export default class App extends Component {
  render() {
    return (
      <>
        <Toaster
          position="top-right"
          reverseOrder={false}
          containerStyle={{
            top: 70,
            right: 20,
          }}
        />
        <Switch>
          <Route path="/loginsuccess" component={LoginHandler} />
          <Route path="/loginfailed" component={LoginHandler} />
          <Route path="/login" component={LoginContainer} />
          <Route path="/signup" component={LoginContainer} />
          <Route path="/about" component={AboutContainer} />
          <Route path="/feedback" component={FeedbackContainer} />
          <Route path="/account" component={RequireAuth(AccountPage)} />
          <Route path="/share" component={RequireAuth(SharePage)} />
          <Route path="/404Error" component={Page404} />

          {/* <Route path="/history" component={RequireAuth(HistoryContainer)} /> */}
          <Route path="/" component={HomeContainer} />
        </Switch>
      </>
    );
  }
}
