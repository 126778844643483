// console.log("materailize script");
// document.addEventListener("DOMContentLoaded", function () {
//   var tooltipelems = document.querySelectorAll(".tooltipped");
//   var tooltipinstances = M.Tooltip.init(tooltipelems);
//   var dropdownelems = document.querySelectorAll(".dropdown-trigger");
//   var dropdowninstances = M.Dropdown.init(dropdownelems);
//   console.log(instances);
// });

export function intializeAll() {
  // console.log("ialiace lall");
  // var tooltipelems = document.querySelectorAll(".tooltipped");
  var dropdownelems = document.querySelectorAll(".dropdown-trigger");
  var collapElems = document.querySelectorAll(".collapsible");

  var collapInstances = M.Collapsible.init(collapElems);
  var dropdowninstances = M.Dropdown.init(dropdownelems);
  // var tooltipinstances = M.Tooltip.init(tooltipelems);
}
export function intializeSelect() {
  var elems = document.querySelectorAll('select');
  var instances = M.FormSelect.init(elems);
}