import React from 'react'

function LetterAvatar({ user }) {
    return (
        user.imageurl ?
            <img
                className="circle responsive-img"
                src={user.imageurl}
                alt={user.username}
            /> :
            <div className="letter circle">
                <p>{user.username[0].toUpperCase()}</p>
            </div>
    )
}

export default LetterAvatar
