import { modalState } from "./defaultstate";
import { SHOW_MODAL, HIDE_MODAL } from "./constactions";
import { EDIT_USER_FAIL, EDIT_USER_SUCCESS, SAVE_VIDEO_SUCCESS } from "../user/constactions";

const modalReducer = (state = modalState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      // console.log(action.modalId);
      return {
        ...state,
        isOpen: true,
        modalId: action.modalId,
        urlId: action.urlId,
      };
    case SAVE_VIDEO_SUCCESS:
      return { ...state, isOpen: false, modalId: null, urlId: null }
    case HIDE_MODAL:
      return { ...state, isOpen: false, modalId: null, urlId: null };

    case EDIT_USER_SUCCESS:
      return { ...state, isOpen: false, modalId: null }
    case EDIT_USER_FAIL:
      return { ...state, isOpen: false, modalId: null }
    default:
      return state;
  }
};

export default modalReducer;
