import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension'
// import { logger } from "redux-logger";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";

import combinedReducer from "./combinedreducer.js";

export default createStore(
  combinedReducer,
  composeWithDevTools(applyMiddleware(promise, thunk))

);
