import React from "react";
import { Link } from "react-router-dom";

export default function List() {
  return (
    <ul className="hide-on-mobile">
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <Link to="/about">About</Link>
      </li>
      <li>
        <Link to="/feedback">Feedback</Link>
      </li>
      {/* <li>
        <Link to="/help">Help</Link>
      </li> */}
    </ul>
  );
}
