import React, { Component } from "react";

class Search extends Component {
  render() {
    return (
      <div className="valign-wrapper search">
        {/* <label htmlFor="search">Search:</label>
          <input
            className="browser-default"
            id="search"
            type="search"
            placeholder="Search"
            required
          />
          <button className="material-icons">search</button> */}
      </div>
    );
  }
}
export default Search;
