import React, { Component } from "react";
import MenuLinks from "./menulinks.js";
import SVG from "./svg";

class MenuBtn extends Component {
  constructor(props) {
    super(props);
    this.menuRef = React.createRef();
  }
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside)
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }
  toggleMenu = (e) => {
    let menu = document.querySelector(".home-menu");
    if (menu.classList.contains("show-menu")) {
      menu.classList.remove("show-menu");
    } else {
      menu.classList.add("show-menu");
    }
  };
  hideMenu = () => {
    let menu = document.querySelector(".home-menu");
    if (menu.classList.contains("show-menu")) {
      menu.classList.remove("show-menu");
    }
  }
  handleClickOutside = (event) => {
    if (this.menuRef.current && !this.menuRef.current.contains(event.target)) {
      this.hideMenu();
    }
  }
  render() {
    return (
      <div className="menu-btn show-on-mobile" ref={this.menuRef}>
        <SVG handleClick={this.toggleMenu} />
        <MenuLinks />
      </div>
    );
  }
}

export default MenuBtn;
