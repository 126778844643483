import React, { Component } from "react";
import { connect } from "react-redux";
import { saveLocation } from "./saveRoutesLocalStorage.js";

//HoC for check authentication
export default (ComposedComponent) => {
  class RequireAuth extends Component {
    componentDidMount() {
      if (this.props.user === null) {
        saveLocation(this.props.location.pathname);
        // console.log(
        //   "🚀 ~ file: protectedroute.js ~ line 11 ~ RequireAuth ~ componentDidMount ~ pathname",
        //   this.props.location.pathname
        // );
        this.props.history.push("/login");
      }
      // console.log("succccc");
    }
    componentDidUpdate() {
      if (this.props.user === null) {
        this.props.history.push("/login")
      }
    }

    render() {
      if (this.props.user) {
        return <ComposedComponent {...this.props} />;
      }
      return null;
    }
  }

  function mapStateToProps({ userState }) {
    return {
      user: userState.user,
    };
  }
  return connect(mapStateToProps, null)(RequireAuth);
};
