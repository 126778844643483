import React from "react";
import { Link, withRouter } from "react-router-dom";

import SelectContainer from "./SelectContainer";
function MenuLinks({ location }) {
  const isActive = (url) => {
    if (url === location.pathname) {
      return 'active'
    }
    return ''
  }
  return (
    <ul className="home-menu">
      <AnchorTag url="/" label="Home" isActive={isActive} />
      <AnchorTag url="/about" label="About" isActive={isActive} />
      <AnchorTag url="/feedback" label="Feedback" isActive={isActive} />
      {/* <AnchorTag url="/help" label="Help" isActive={isActive} /> */}
      <SelectContainer />
    </ul>
  );
}
export default withRouter(MenuLinks);

function AnchorTag({ url, isActive, label }) {
  return <li className={isActive(url)} > <Link to={url}>{label}</Link></li >
}