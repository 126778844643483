import React from "react";
import { Link } from "react-router-dom";

export default function LoginBtn({ clickHandler }) {
  return (
    // <a
    //   href="/auth/login/google"
    //   className="login-btn waves-effect waves-red btn"
    // >
    //   <i className="material-icons left">lock_outline</i>
    //   Login
    // </a>
    <Link to="/login" className="login-btn waves-effect waves-red btn">
      <i className="material-icons left">lock_outline</i>
      Login
    </Link>
  );
}
