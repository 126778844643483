import React, { Component } from "react";
import { connect } from "react-redux";
import { loginUser } from "../../redux/user/actionCreators/Autherization";
import { getLocation } from "./saveRoutesLocalStorage.js";

class LoginHandler extends Component {
  componentDidMount() {
    //check if user is there
    // console.log("loginsuccess");
    this.props.dispatch(loginUser());
  }
  componentDidUpdate() {
    if (this.props.user) {
      // alert("User is signned in succesfully.");
      let from = getLocation();
      // console.log(from);
      if (from) {
        this.props.history.push(from);
      } else {
        this.props.history.push("/");
      }
    }
  }
  render() {
    return <div>Signing in...</div>;
  }
}
function mapStateToProps({ userState }) {
  return {
    user: userState.user,
  };
}
export default connect(mapStateToProps, null)(LoginHandler);
