export const videoState = {
  error: "",
  isVisible: false,
  addSuccess: false,
  fetchSuccess: false,

  needFetch: false,
  waiting: true,
  full: false,
  videos: [],
  orderList: [],
  updateSuccess: "",
  skipCount: 0,
  playing: false,
  playingVideo: {},
};
