import React,{Component} from "react";
import {withRouter} from 'react-router-dom';
import './pagelabel.css';

class MobilePageLabel extends Component{
    state={
            label:'Loading',
            isShow:false,
    }
    componentDidMount(){
        if(this.props.location.pathname==="/favorites"){
            this.setState({isShow:true,label:"Favorites"})
        }
    }
    componentDidUpdate(prevProps,prevState){
        let {location}=this.props;
        let {isShow}=this.state;
        if(location.pathname==="/favorites"){
            if(!isShow){
                this.setState({isShow:true,label:"Favorites"})
            }
        }
        else if(isShow){
            this.setState({isShow:false,label:""})
        }
    }

    render(){
        let {isShow,label}=this.state;
        return isShow?(
            <div className="page-label">
                <p>{label}</p>
            </div>
        )
        :null;
    }
}
export default withRouter(MobilePageLabel);