import axiosInstance from "../axiosInstance";

import {
  FETCH_STUFF_SUCCESS,
  FETCH_STUFF_ERROR,
  LANGUAGE_ADDED,
  CATEGORY_ADDED,
  SET_LANGUAGE,
  SET_CATEGORY,
} from "./constactions.js";
// db.stuffs.deleteOne({ _id: ObjectId("5f793a7eaf54682a70566a8e")});

///Fetching languages and categoriesH
export function fetchCategoryList() {
  return function (dispatch) {
    axiosInstance
      .get("/stuff")
      .then((response) => {
        dispatch(fetchStuffFullfill(response.data[0]));
      })
      .catch((err) => {
        dispatch(fetchStuffError(err));
      });
  };
}

function fetchStuffFullfill({ language, category }) {
  return {
    type: FETCH_STUFF_SUCCESS,
    categories: category,
    languages: language,
  };
}
function fetchStuffError(error) {
  return {
    type: FETCH_STUFF_ERROR,
    error,
  };
}
export function setLanguage(language) {
  return {
    type: SET_LANGUAGE,
    language,
  };
}
export function setCategory(category) {
  return {
    type: SET_CATEGORY,
    category,
  };
}
////add language
export function addLanguage(language) {
  return function (dispatch) {
    axiosInstance
      .post("/stuff/addLanguage", { language })
      .then((response) => {
        alert(response.data);
        dispatch(addLanguageSuccess(language));
      })
      .catch((err) => {
        alert("ERR: Language is not Added. Please see console for more info.");
        console.error(err);
      });
  };
}
function addLanguageSuccess(newlanguage) {
  return {
    type: LANGUAGE_ADDED,
    newlanguage,
  };
}

//add CATEGORIES
export function addCategory(category, icon) {
  return function (dispatch) {
    axiosInstance
      .post("/stuff/addcategory", { category, icon })
      .then((response) => {
        alert(response.data);
        dispatch(addCategorySuccess(category, icon));
      })
      .catch((err) => {
        alert("ERR: category is not Added. Please see console for more info.");
        console.error(err);
      });
  };
}
function addCategorySuccess(newcategory, newicon) {
  return {
    type: CATEGORY_ADDED,
    newcategory,
    newicon,
  };
}
