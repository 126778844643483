import React from "react";

export default function SVG({ handleClick }) {
  return (
    <>
      <svg
        onClick={handleClick}
        width="40"
        height="25"
        viewBox="0 0 40 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H40V4.80769H0V0ZM0 10.0963H40V14.904H0V10.0963ZM40 20.1924H0V25.0001H40V20.1924Z"
          fill="#4F6C52"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H40V4.80769H0V0ZM0 10.0963H40V14.904H0V10.0963ZM40 20.1924H0V25.0001H40V20.1924Z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="16.5"
            y1="12"
            x2="-1.24025e-06"
            y2="22.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E2C0C0" />
            <stop offset="1" stopColor="#51A692" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
}
