import { FETCH_QUOTES_ERROR, FETCH_QUOTES_SUCCESS } from './constactions';
import quoteState from './defaultState';


export const quoteReducer = (state = quoteState, action) => {
    switch (action.type) {
        case FETCH_QUOTES_SUCCESS:
            return {
                ...state,
                quotes: [...state.quotes, ...action.quotes],
                isError: false,
                isAvailable: true
            };
        case FETCH_QUOTES_ERROR:
            return {
                ...state,
                isError: true,
                isAvailable: false,
            }
        default:
            return state;
    }
}