//playing
export const PLAYING_VIDEO = "PLAYING_VIDEO";
export const PLAY_OFF = "PLAY_OFF";

//about videos
export const DELETE_VIDEO = "DELETE_VIDEO";
export const VIDEO_UPDATED = "VIDEO_UPDATED";

export const ADDED_VIDEO_SUCCESS = "ADDED_VIDEO_SUCCESS";
export const ADDED_VIDEO_ERROR = "ADDED_VIDEO_ERROR";

export const FETCH_VIDEOS_FULLFILLED = "FETCH_VIDEOS_FULLFILLED";
export const FETCH_VIDEOS_ERROR = "FETCH_VIDEOS_ERROR";
export const SCROLL_FETCH_NEEDED = "SCROLL_FETCH_NEEDED";
export const WAITING = "WAITING";
export const SCROLL_FETCH_FULLFILLED = "SCROLL_FETCH_FULLFILLED";
export const LIST_FULL = "LIST_FULL";
export const SWITCH_FAVORITE_SUCCESS = "SWITCH_FAVORITE_SUCCESS";
