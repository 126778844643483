import { userState } from "./defaultstate.js";
import {
  LOGIN,
  LOGOUT,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  SAVE_VIDEO_SUCCESS,
  SAVE_VIDEO_FAIL,
  FAVORITES_FULFILLED,
  FAVORITES_ERROR,
  SWITCH_FAVORITE_SUCCESS,
  EDIT_USER_SUCCESS,
  SAVED_FULFILLED,
  SAVED_FAIL,
  DELETE_SAVED_SUCCESS,
  SIGNUP_SUCCESS,
  SIGNUP_FAILED,
  SIGNUP_RESET,
  LOGIN_ERROR_RESET
} from "./constactions.js";

import { returnFavorites } from "./helperFunctions";

const userReducer = (state = userState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user,
        isAuthenticated: true,
      };
    case LOGIN_FAILED:
      return { ...state, user: null, isAuthenticated: false, isLoginError: true };
    case LOGIN_ERROR_RESET:
      return { ...state, isLoginError: null }
    case LOGOUT:
      return { ...state, user: null, isAuthenticated: false, favorites: [], history: [], savedVideos: [] };
    case SIGNUP_SUCCESS:
      return { ...state, user: null, signUp: "success" }

    case SIGNUP_FAILED:
      return { ...state, user: null, signUp: "failed" }
    case SIGNUP_RESET:
      return { ...state, user: null, signUp: "" }
    case SAVE_VIDEO_SUCCESS:
      return { ...state, savedVideos: [...state.savedVideos, action.videoObj] };
    case SAVE_VIDEO_FAIL:
      return { ...state, savedError: action.err };
    case SAVED_FULFILLED:
      return { ...state, savedVideos: [...state.savedVideos, ...action.videos] }
    case SAVED_FAIL:
      return { ...state, savedError: action.err }
    case DELETE_SAVED_SUCCESS:
      return { ...state, savedVideos: state.savedVideos.filter(ele => ele._id !== action.videoId) }

    case FAVORITES_FULFILLED:
      return {
        ...state,
        favorites: [...state.favorites, ...action.videos],
        isFav: true,
        favCount: action.favCount,
        favError: null,
      };
    case FAVORITES_ERROR:
      return { ...state, favError: action.favError };
    case SWITCH_FAVORITE_SUCCESS:
      // return {...state,favorites:state.favorites.filter(ele=>ele.videoId!==action.videoId)}
      return {
        ...state,
        favorites: returnFavorites({
          isFavorite: action.isFavorite,
          videoObj: action.videoObj,
          prevFavorites: state.favorites,
        }),
      };

    case EDIT_USER_SUCCESS:
      let { username, email, name } = action
      return {
        ...state,
        user: { ...state.user, username, email, name }
      }

    default:
      return state;
  }
};
export default userReducer;
//NO NEED TO USE BRAK BECAUSE OF RETURN STATEMENT

// const stuffReducer = (state = stuffState, action) => {
//   switch (action.type) {
//     case FETCH_STUFF_SUCCESS:
//       return {
//         ...state,
//         languages: [...action.languages],
//         categories: [...action.categories],
//         icons: [...action.icons],
//         isAvailable: true,
//       };
//     case FETCH_STUFF_ERROR:
//       return { ...state, isAvailable: false, error: action.error };
//     case LANGUAGE_ADDED:
//       return {
//         ...state,
//         isAvailable: true,
//         languages: [...state.languages, action.newlanguage],
//       };
//     case CATEGORY_ADDED:
//       return {
//         ...state,
//         isAvailable: true,
//         categories: [...state.categories, action.newcategory],
//         icons: [...state.icons, action.newicon],
//       };
//     default:
//       return state;
//   }
// };
