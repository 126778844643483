import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./languagecontainer.css";
import LanguageList from "./LanguageList.js";
import IconLinks from "./IconLinks";
import Search from "./SearchComponent";
import MobilePageLabel from '../SubComponents/MobilePageLabel';
import { setLanguage } from "../../../redux/stuff/actioncreators";

class LanguageNavContainer extends Component {
  languageHandler = (e) => {
    // console.log("clicked", e.target.innerHTML.toLowerCase());
    let language = e.target.innerHTML.toLowerCase();
    this.props.dispatch(setLanguage(language));
  };

  render() {
    return (
      <>
      <div className="valign-wrapper language-container">
        <div>
          <LanguageList
            list={this.props.languageList}
            current={this.props.selectedLanguage}
            clickHandler={this.languageHandler}
          />
        </div>
        <IconLinks />
        <Search />
      </div>
        <MobilePageLabel/>
      </>
    );
  }
}
function mapStateToProps({ stuffState, userState }) {
  return {
    languageList: stuffState.languages,
    selectedLanguage: stuffState.selectedLanguage,
  };
}
export default connect(mapStateToProps, null)(withRouter(LanguageNavContainer));
