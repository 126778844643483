import { videoState } from "./defaultstate.js";

import {
	FETCH_VIDEOS_ERROR,
	FETCH_VIDEOS_FULLFILLED,
	SCROLL_FETCH_FULLFILLED,
	SCROLL_FETCH_NEEDED,
	WAITING,
	LIST_FULL,
	ADDED_VIDEO_ERROR,
	ADDED_VIDEO_SUCCESS,
	DELETE_VIDEO,
	VIDEO_UPDATED,
	PLAYING_VIDEO,
	PLAY_OFF,
	HIDE_COMPONENT,
	SWITCH_FAVORITE_SUCCESS,
} from "./constactions.js";
import { LOGIN_SUCCESS, LOGOUT } from '../user/constactions';
import { handleFavoriteSwitch, returnVideoState } from "./helperFunctions";

const videoReducer = (state = videoState, action) => {
	switch (action.type) {
		case FETCH_VIDEOS_FULLFILLED:
			return {
				...state,
				videos: [...action.videos],
				fetchSuccess: true,
				needFetch: false,
				skipCount: action.skipCount,
				waiting: false,
				orderList: [...state.orderList, ...action.orderList],
			};

		case FETCH_VIDEOS_ERROR:
			return { ...state, error: action.err, fetchSuccess: false, waiting: false };

		case SCROLL_FETCH_FULLFILLED:
			return {
				...state,
				videos: [...state.videos, ...action.videos],
				orderList: [...state.orderList, ...action.orderList],
				fetchSuccess: true,
				skipCount: action.skipCount,
				needFetch: false,
				waiting: false,
			};

		case WAITING:
			return { ...state, waiting: true };

		case SCROLL_FETCH_NEEDED:
			return { ...state, needFetch: true, fetchSuccess: false };

		case LIST_FULL:
			return { ...state, needFetch: false, full: true, waiting: false };

		case ADDED_VIDEO_SUCCESS:
			return { ...state, addSuccess: true, isVisible: true };

		case ADDED_VIDEO_ERROR:
			return {
				...state,
				addSuccess: false,
				isVisible: true,
				error: action.err,
			};

		case DELETE_VIDEO:
			return {
				...state,
				videos: state.videos.filter((ele) => ele._id !== action.id),
			};

		case VIDEO_UPDATED:
			return { ...state, updateSuccess: action.result };

		case PLAYING_VIDEO:
			return {
				...state,
				playing: true,
				playingVideo: state.videos.filter((ele) => ele.id === action.id),
			};

		case PLAY_OFF:
			return { ...state, playingVideo: {}, playing: false };

		case HIDE_COMPONENT:
			return { ...state, isVisible: false };

		case SWITCH_FAVORITE_SUCCESS:
			return {
				...state,
				videos: handleFavoriteSwitch({
					isFavorite: action.isFavorite,
					videoObj: action.videoObj,
					prevVideos: state.videos,
				}),
			};

		case LOGIN_SUCCESS:
			return {
				...state,
				fetchSuccess: false,
				needFetch: false,
				waiting: false,
				full: false,
				videos: [],
				orderList: [],
				skipCount: 0,
				playing: false,
				playingVideo: {}
			}

		case LOGOUT:
			return {
				...state,
				fetchSuccess: false,
				videos: [],
				skipCount: 0,
				playingVideo: {},
				full: false,
				waiting: false,
				needFetch: false,
				playing: false,
			}

		default:
			return state;
	}
};

export default videoReducer;
