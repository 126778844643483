import {
  PLAY_URL_VIDEO,
  PLAY_VIDEO,
  PLAY_VIDEO_ERROR,
  IS_PLAYING,
} from "./constactions.js";
import { SWITCH_FAVORITE_SUCCESS } from '../user/constactions';
import { playerState } from "./defaultstate";
import { returnVideo } from './helperFunctions';
const playerReducer = (state = playerState, action) => {
  switch (action.type) {
    case PLAY_URL_VIDEO:
      // console.log(action, "action");
      return {
        ...state,
        isAvailable: true,
        isError: false,
        playingVideo: action.video,
        error: null,
      };
    case PLAY_VIDEO:
      return {
        ...state,
        isAvailable: true,
        isError: false,
        playingVideo: action.video,
        error: null,
      };
    case IS_PLAYING:
      return {
        ...state,
        isPlaying: action.value,
      };
    case PLAY_VIDEO_ERROR:
      return { ...state, error: action.error, isError: true };
    case SWITCH_FAVORITE_SUCCESS:
      return { ...state, playingVideo: returnVideo(action.isFavorite, action.videoObj, state.playingVideo) }
    default:
      return state;
  }
};
export default playerReducer;

// case DELETE_FEEDBACK_SUCCESS:
//   return {
//     ...state,
//     list: state.list.filter((ele) => ele._id !== action.id),
//   };
// case DELETE_FEEDBACK_ERROR:
//   return {
//     ...state,
//     error: action.error,
//   };
// case ADD_FEEDBACK_SUCCESS:
//   return {
//     ...state,
//     list: [action.newobj, ...state.list],
//     addSuccess: true,
//     error: undefined,
//   };
// case ADD_FEEDBACK_ERROR:
//   return { ...state, addSuccess: false, error: action.error };
