import React, { Component } from "react";
import { connect } from "react-redux";
import {
  loginUser,
  logoutUser,
} from "../../../redux/user/actionCreators/Autherization";
import UserMenu from "./usermenu.js";
import LoginBtn from "./loginBtn.js";
import LetterAvatar from "./LetterAvatar";

class UserBtn extends Component {
  constructor(props) {
    super(props);
    this.menu;
    this.menuRef = React.createRef();
  }
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside)
    if (this.props.isAuthenticated === false) {
      this.props.dispatch(loginUser());
    }
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }
  showHideMenu = (e) => {
    if (e.target.tagName == "IMG" || e.target.tagName === "A" || e.target.tagName === 'DIV' || e.target.tagName === 'P') {
      this.menu = document.querySelector(".user-menu");
      if (this.menu.classList.contains("show-menu")) {
        this.menu.classList.remove("show-menu");
      } else {
        this.menu.classList.add("show-menu");
      }
    }
  };
  hideMenu = () => {
    let menu = document.querySelector(".user-menu");
    if (menu.classList.contains("show-menu")) {
      menu.classList.remove("show-menu");
    }
  }
  handleLogout = () => {
    this.props.dispatch(logoutUser());
  };
  handleClickOutside = (event) => {
    if (this.menuRef.current && !this.menuRef.current.contains(event.target)) {
      this.hideMenu();
    }
  }
  render() {
    let user = this.props.user;
    return (
      <>
        {user != null ? (
          <div onClick={this.showHideMenu} ref={this.menuRef} className="user-btn">
            <LetterAvatar user={user} />
            <UserMenu user={user} handleLogout={this.handleLogout} />
          </div>
        ) : (
          <LoginBtn />
        )}
      </>
    );
  }
}
function mapStateToProps({ userState }) {
  return {
    isAuthenticated: userState.isAuthenticated,
    user: userState.user,
  };
}
export default connect(mapStateToProps, null)(UserBtn);
