import axiosInstance from "../../axiosInstance";

import { LOGIN, LOGIN_FAILED, LOGIN_SUCCESS, LOGOUT, SIGNUP_FAILED, SIGNUP_SUCCESS } from "../constactions.js";

export function Login({ username, password }) {
  return function (dispatch) {
    console.log("username,password", username, password);
    axiosInstance
      .post(
        "/auth/local/login",
        { username, password },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.data === "not authenticated") {
          dispatch(loginUserSuccess(null));
        } else {
          dispatch(loginUserSuccess(response.data));
        }
      })
      .catch((err) => {
        console.log('login ERR: ', err);
        dispatch(loginUserFailed(err.message));
      });
  };
}
// *.local, 169.254/16
export function loginUser() {
  //call api for login
  return function (dispatch) {
    axiosInstance
      .get("/auth/login", {
        withCredentials: true,
        mode: "cors",
      })
      .then((response) => {
        // console.log(
        //   "🚀 ~ file: actioncreators.js ~ line 28 ~ .then ~ response",
        //   response
        // );

        if (response.data === "not authenticated") {
          dispatch(loginUserFailed(null));
        } else {
          // console.log("user: ", response.data);
          dispatch(loginUserSuccess(response.data));
        }
      })
      .catch((err) => {
        dispatch(loginUserFailed(err.message));
      });
  };
}
export function logoutUser() {
  return function (dispatch) {
    axiosInstance
      .get("/auth/logout", { withCredentials: true })
      .then((response) => {
        dispatch({ type: LOGOUT })
      })
      .catch((err) => {
        console.log("ERR LOGOUT: ", err);
      });
  }
}
function loginUserSuccess(user) {
  return {
    type: LOGIN_SUCCESS,
    user,
  };
}
export function loginUserFailed(err) {
  return {
    type: LOGIN_FAILED,
    err,
  };
}

export function SignUp({ username, password, email, name }) {
  return function (dispatch) {
    axiosInstance.post("/auth/signup", { username, password, email, name })
      .then(response => {
        if (response.data === 'success') {
          dispatch({ type: SIGNUP_SUCCESS })
        }
      })
      .catch(err => {
        console.log('SIGNUP ERR: ', err);
        dispatch({ type: SIGNUP_FAILED })
      })
  }
}
