import { feedback } from "./defaultstate";
import {
  FETCH_FEEDBACK_ERROR,
  FETCH_FEEDBACK_SUCCESS,
  DELETE_FEEDBACK_ERROR,
  DELETE_FEEDBACK_SUCCESS,
  ADD_FEEDBACK_ERROR,
  ADD_FEEDBACK_SUCCESS,
} from "./constactions";

const feedbackReducer = (state = feedback, action) => {
  switch (action.type) {
    case FETCH_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedbackList: [...state.feedbackList, ...action.feedbackList],
        isAvailable: true,
        error: undefined,
        skipCount: action.skipCount,
      };
    case FETCH_FEEDBACK_ERROR:
      return { ...state, error: action.error, isAvailable: false };
    case DELETE_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedbackList: state.feedbackList.filter((ele) => ele._id !== action.id),
      };
    case DELETE_FEEDBACK_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case ADD_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedbackList: [action.newobj, ...state.feedbackList],
        addSuccess: true,
        error: undefined,
      };
    case ADD_FEEDBACK_ERROR:
      return { ...state, addSuccess: false, error: action.error };
    default:
      return state;
  }
};

export default feedbackReducer;
