//NAME  OF ACTIONS
//login
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_ERROR_RESET = "LOGIN_ERROR_RESET"
//Signup
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS"
export const SIGNUP_FAILED = "SIGNUP_FAILED"
export const SIGNUP_RESET = "SIGNUP_RESET"

//user
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAIL = "EDIT_USER_FAIL";


//SAVE Videos
export const SAVE_VIDEO_SUCCESS = "SAVE_VIDEO_SUCCESS";
export const SAVE_VIDEO_FAIL = "SAVE_VIDEO_FAIL";
export const SAVED_FULFILLED = "SAVED_FULFILLED";
export const SAVED_FAIL = "SAVED_FAIL";
export const DELETE_SAVED_SUCCESS = "DELETE_SAVED_SUCCESS"

// favorites videos
export const FAVORITES_FULFILLED = "FAVORITES_FULFILLED";
export const FAVORITES_ERROR = "FAVORITES_ERROR";
export const SWITCH_FAVORITE_SUCCESS = "SWITCH_FAVORITE_SUCCESS"